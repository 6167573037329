import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import { Role } from "../_helpers";
import { accountService, selectService } from "../_services";
import { Nav, PrivateRoute, Alert } from "../_components";
import { Home } from "../home";
import { Profile } from "../profile";
import { Admin } from "../admin";
import { Account } from "../account";
import { Sites } from "../pages/sites";
import { Projects } from "../pages/projects";
import { Ubps } from "../pages/ubps";
import { Operations } from "../pages/operations";
import { Fundings } from "../pages/fundings";
import { Erp } from "../pages/erp";
import { Capex } from "../pages/capex";

function App() {
  const location = useLocation();
  const pathname = location.pathname;
  const [user, setUser] = useState(accountService.userValue);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const subscription = accountService.user.subscribe((x) => setUser(x));
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (user && !selectService.value && !user.needsPinVerification) {
      setLoading(true);
      selectService.load().finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [user]);

  const content = (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/admin" roles={[Role.Admin]} component={Admin} />
      <PrivateRoute path="/sites" component={Sites} />
      <PrivateRoute path="/projects" component={Projects} />
      <PrivateRoute path="/operations" component={Operations} />
      <PrivateRoute path="/funding" component={Fundings} />
      <PrivateRoute
        path="/pl"
        roles={[
          Role.Admin,
          Role.Controller,
          Role.FM,
          Role.PM_PD,
          Role.ReadOnlyUnlimited,
        ]}
        component={Erp}
      />
      <PrivateRoute
        path="/capex"
        roles={[
          Role.Admin,
          Role.Controller,
          Role.FM,
          Role.PM_PD,
          Role.ReadOnlyUnlimited,
        ]}
        component={Capex}
      />
      <PrivateRoute
        path="/ubps"
        roles={[
          Role.Admin,
          Role.Controller,
          Role.FM,
          Role.PM_PD,
          Role.ReadOnlyUnlimited,
        ]}
        component={Ubps}
      />
      <Route path="/account" component={Account} />
      <Redirect from="*" to="/" />
    </Switch>
  );

  return (
    <div className={"app-container" + (user && " bg-light")}>
      <Nav />
      <div style={{ marginTop: "10px" }}>
        <Alert />
        {loading ? (
          <div className="text-center">
            <div
              className="spinner-border m-5"
              style={{ width: "5rem", height: "5rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          content
        )}
      </div>
    </div>
  );
}

export { App };
