import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import { accountService } from "../_services";

import { Login } from "./Login";
import { Register } from "./Register";
import { VerifyEmail } from "./VerifyEmail";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { Pin } from "./Pin";

function Account({ history, match }) {
  const { path } = match;

  useEffect(() => {
    // redirect to home if already logged in && no pin needed
    if (
      accountService.userValue &&
      !accountService.userValue.needsPinVerification
    ) {
      history.push("/");
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-8 offset-sm-2 mt-5">
            <div className="card m-3">
              <Switch>
                <Route path={`${path}/login`} component={Login} />
                <Route path={`${path}/pin`} component={Pin} />
                <Route path={`${path}/register`} component={Register} />
                <Route path={`${path}/verify-email`} component={VerifyEmail} />
                <Route
                  path={`${path}/forgot-password`}
                  component={ForgotPassword}
                />
                <Route
                  path={`${path}/reset-password`}
                  component={ResetPassword}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <footer
        style={{ position: "relative", width: "100%", textAlign: "center" }}
      >
        <a href="https://h2-mobility.de/impressum/">Impressum</a>
      </footer>
    </>
  );
}

export { Account };
