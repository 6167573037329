import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { accountService, alertService } from "../../_services";
import { Role } from "../../_helpers";

function AddEdit({ history, match }) {
  const { id } = match.params;
  const isAddMode = isNaN(id);

  let formikSetFieldValue = () => {};

  useEffect(() => {
    if (!isAddMode) {
      // get user and set form fields
      accountService.getById(id).then((user) => {
        const fields = [
          "active",
          "firstName",
          "lastName",
          "email",
          "role",
          "verificationPinMode",
        ];
        fields.forEach((field) =>
          formikSetFieldValue(field, user[field], false)
        );
      });
    }
  }, []);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: "ReadOnlyLimited",
    password: "",
    confirmPassword: "",
    active: true,
    verificationPinMode: 0,
  };

  const validationSchema = Yup.object().shape({
    active: Yup.bool(),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    role: Yup.string().required("Role is required"),
    verificationPinMode: Yup.number()
      .oneOf(
        [0, 1, 2],
        "Email-Pin Verification must be one the selectable options"
      )
      .required("Email-Pin Verification is required"),
    password: Yup.string()
      .concat(isAddMode ? Yup.string().required("Password is required") : null)
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .when("password", ([password], schema) => {
        if (password) return schema.required("Confirm Password is required");
      })
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createUser(fields, setSubmitting);
    } else {
      updateUser(id, fields, setSubmitting);
    }
  }

  function createUser(fields, setSubmitting) {
    accountService
      .create(fields)
      .then(() => {
        alertService.success("User erfolgreich hinzugefügt.", {
          keepAfterRouteChange: true,
        });
        history.push(".");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  function updateUser(id, fields, setSubmitting) {
    accountService
      .update(id, fields)
      .then(() => {
        alertService.success("Aktualierisung erfolgreich", {
          keepAfterRouteChange: true,
        });
        history.push("..");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        formikSetFieldValue = setFieldValue;
        return (
          <Form>
            <h1>{isAddMode ? "Benutzer hinzufügen" : "Benutzer bearbeiten"}</h1>
            <div className="form-row">
              <div className="form-group col-5">
                <label>Vorname</label>
                <Field
                  name="firstName"
                  type="text"
                  className={
                    "form-control" +
                    (errors.firstName && touched.firstName ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col-5">
                <label>Nachname</label>
                <Field
                  name="lastName"
                  type="text"
                  className={
                    "form-control" +
                    (errors.lastName && touched.lastName ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col">
                <label>Rolle</label>
                <Field
                  name="role"
                  as="select"
                  className={
                    "form-control" +
                    (errors.role && touched.role ? " is-invalid" : "")
                  }
                >
                  {Object.entries(Role).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="role"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="--group col-7">
                <label>Email</label>
                <Field
                  name="email"
                  type="text"
                  className={
                    "form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col">
                <label>Email-Pin Verifikation</label>
                <Field
                  name="verificationPinMode"
                  as="select"
                  className={
                    "form-control" +
                    (errors.verificationPinMode && touched.verificationPinMode
                      ? " is-invalid"
                      : "")
                  }
                >
                  <option key={0} value={0}>
                    {"Bei jedem Login"}
                  </option>
                  <option key={1} value={1}>
                    {"Alle 3 Monate"}
                  </option>
                  <option key={2} value={2}>
                    {"Niemals"}
                  </option>
                </Field>
                <ErrorMessage
                  name="verficationPinMode"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label>Aktiv</label>
                <Field
                  name="active"
                  type="checkbox"
                  className={
                    "form-control" +
                    (errors.active && touched.active ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="active"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            {!isAddMode && (
              <div>
                <h3 className="pt-3">Change Password</h3>
                <p>Felder leer lassen, um das Passwort beizubehalten</p>
              </div>
            )}
            <div className="form-row">
              <div className="form-group col">
                <label>Passwort</label>
                <Field
                  name="password"
                  type="password"
                  className={
                    "form-control" +
                    (errors.password && touched.password ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col">
                <label>Passwort wiederholen</label>
                <Field
                  name="confirmPassword"
                  type="password"
                  className={
                    "form-control" +
                    (errors.confirmPassword && touched.confirmPassword
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Speichern
              </button>
              <Link to={isAddMode ? "." : ".."} className="btn btn-link">
                Abbrechen
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export { AddEdit };
